/*Skills Section*/

.skills-display {
    width: 75%;
    padding: 10px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.skill-progress {
    width: 11rem;
    height: 2.2rem;
    background-color: lightgrey;
    border-radius: 1rem;
    box-shadow: 1px 1px 2px 1px #b9b9b9 inset;
    margin: 25px;
    overflow: hidden;
}

.skill-progress > div {
    border-radius: 20px 0px 0px 20px;
    box-shadow: 1px 1px 5px 2px #989882;
}

.skill-name span {
    color: white;
    font-size: 1rem;
    margin-left: 10px;
    vertical-align: middle;
}
