.skill-progress {
    width: 11rem;
    height: 2.2rem;
    background-color: lightgrey;
    border-radius: 1rem;
    box-shadow: 1px 1px 2px 1px #b9b9b9 inset;
    margin: 25px;
    overflow: hidden;
}

/*Percentage Classes */

.eighty-five-percent {
    height: inherit;
    width: 85%;
}

.eighty-percent {
    height: inherit;
    width: 80%;
}

.fifty-percent {
    height: inherit;
    width: 50%;
}

/* setting background-color */

.m-blue {
    color: #2857a4;
}

.mb-blue {
    background-color: #2857a4;
}

.mb-orange {
    background-color: #ec4d1c;
}

.mb-teal {
    background-color: #0b8176;
}

.mb-light-purple {
    background-color: #93738c;
}

.skill-name span {
    color: white;
    font-size: 1rem;
    margin-left: 10px;
    vertical-align: middle;
}

