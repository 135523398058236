/*About Section*/

.about {
    height: auto;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
}

.my-image {
    height: 12rem;
    width: 12rem;
    margin: -17vh auto auto;
}

.my-image img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 3px solid white;
}

.my-image img:hover {
    box-shadow: 1px 1px 10px 2px lightgrey;
}

.about-para {
    padding: 10px;
    width: 70%;
    text-align: justify;
    color: grey;
    line-height: 28px;
    font-size: 1.1rem;
    margin: auto;
}

@media screen and (max-width: 768px) {
    .my-image {
        height: 8rem;
        width: 8rem;
        margin: -10vh auto auto;
    }
}

@media screen and (max-width: 572px) {
    .my-image {
        height: 6rem;
        width: 6rem;
        margin: -7vh auto auto;
    }
}

.text-highlight {
    color: #2857a4;
    font-weight: 600;
}

.justify-content {
    text-align: center;
}
