.vertical-nav-menu-container {
    display: none;
    color: white;
    position: relative;
    text-align: right;
    font-size: 30px;
}

@media screen and (max-width: 600px) {
    .horizontal-nav-menu-container {
        display: none;
    }

    .body-header {
        padding-top: 0px;
    }
}

/* Horizontal Lists */

.horizontal-list {
    list-style: none;
    padding-left: 0px;
    margin: 0px;
}

.horizontal-list li {
    display: inline-block;
    margin: 0px 8px 8px 0px;
    font-weight: 100;
    font-size: 0.9rem;
}

.horizontal-list li a {
    color: white;
    text-decoration: none;
}

.nav-menu li a {
    transition: color 0.5s, border-bottom 4s;
}

.nav-menu li a:hover {
    color: lightgrey;
    border-bottom: 1px solid black;
}

.text-center {
    text-align: center;
}


.vertical-nav-menu-container {
    display: none;
    color: white;
    position: relative;
    text-align: right;
    font-size: 30px;
}

@media screen and (max-width: 600px) {
    .vertical-nav-menu-container {
        display: block;
    }
}

.vertical-nav-bar-content {
    position: absolute;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    min-width: 90px;
    text-align: center;
    display: none;
}

.vertical-nav-menu-container input:checked + label + .vertical-nav-bar-content {
    display: block;
}

.vertical-nav-bar-content .nav-menu {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.vertical-nav-bar-content .nav-menu li {
    position: relative;
    padding: 0px;
    margin: 0px;
    z-index: 9999999;
    /* border: 1px solid red; */
    height: 20px;
    text-align: right;
}

.vertical-nav-bar-content .nav-menu li a {
    position: absolute;
    right: 5px;
    text-decoration: none;
    color: white;
    font-size: 1rem;
    margin: 0px;
    padding: 0px;
    /* border: 1px solid red; */
}

.vertical-nav-menu-container {
    display: none;
    color: white;
    position: relative;
    text-align: right;
    font-size: 30px;
}

.vertical-nav-menu-container i {
    cursor: pointer;
}

.vertical-nav-menu-container input {
    display: none;
}

.vertical-nav-bar-content {
    position: absolute;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    min-width: 90px;
    text-align: center;
    display: none;
}

.vertical-nav-bar-content .nav-menu {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.vertical-nav-bar-content .nav-menu li {
    position: relative;
    padding: 0px;
    margin: 0px;
    z-index: 9999999;
    /* border: 1px solid red; */
    height: 20px;
    text-align: right;
}

.vertical-nav-bar-content .nav-menu li a {
    position: absolute;
    right: 5px;
    text-decoration: none;
    color: white;
    font-size: 1rem;
    margin: 0px;
    padding: 0px;
    /* border: 1px solid red; */
}

.vertical-nav-menu-container input:checked + label + .vertical-nav-bar-content {
    display: block;
}


@media screen and (max-width: 600px) {
    .vertical-nav-menu-container {
        display: block;
    }
}





