/*Section Classes*/

section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

section:nth-child(2n) {
    background-color: #9e9b9b29;
}

section:nth-child(2n + 1) {
    background-color: white;
}

.section-heading {
    width: auto;
    padding: 20px 10px 10px;
    font-weight: 400;
}

.section-heading span {
    font-size: 40px;
    color: #2857a4;
    display: inline-block;
    padding-top: 10px;
    margin-right: 0.5rem;
}